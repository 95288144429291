import "./About.css";
import React from "react";
import { Splide, SplideSlide, SplideTrack } from "@splidejs/react-splide";
import Marquee from "react-double-marquee";
import dot from "../../assets/img/blue_dot.svg";
import logo_1 from "../../assets/img/logo_1.webp";
import logo_2 from "../../assets/img/logo_2.webp";
import logo_3 from "../../assets/img/logo_3.webp";
import arrow_right from "../../assets/img/arrow_right.svg";
import arrow_left from "../../assets/img/arrow_left.svg";
import comments from "./comments_list.json";

import InfoBlock from "./InfoBlock";
import info from "./info_list.json";

function About() {
  const logos = [logo_1, logo_2, logo_3];
  const duplication = 15;

  const logoElements = [];

  for (let i = 0; i < duplication; i++) {
    logoElements.push(
      <img
        key={i}
        src={logos[i % logos.length]}
        className="about-logo"
        alt=""
      />
    );
  }

  return (
    <div className="full-screen-about-container" id="reviews">
      <div className="about-container">
        <div className="about-section">
          <div className="about-title">
            <img src={dot} className="dot-icon" alt="comments" height={14} />
            <div className="about-section-name">Что о нас говорят клиенты?</div>
          </div>
          <div className="comments-section">
            <Splide
              hasTrack={false}
              aria-label=""
              options={{
                type: "loop",
                autoplay: true,
                pauseOnHover: false,
                resetProgress: false,
              }}
            >
              <div className="splide__arrows">
                <button
                  className="splide__arrow splide__arrow--prev"
                  style={{ display: window.innerWidth > 768 ? "" : "none" }}
                >
                  <img src={arrow_left} alt="left arrow" />
                </button>
                <button
                  className="splide__arrow splide__arrow--next"
                  style={{ display: window.innerWidth > 768 ? "" : "none" }}
                >
                  <img src={arrow_right} alt="right arrow" />
                </button>
              </div>
              <SplideTrack>
                {comments.map((element, index) => {
                  const imagePath = require(`../../${element.avatar}`);
                  return (
                    <SplideSlide key={`splide-${index}`}>
                      <div className="comment-container">
                        <div className="comment-text">{element.text}</div>
                        <div className="comment-signature">
                          <div className="comment-subtitle">
                            <div className="comment-name">{element.name}</div>
                            <div className="autor-description">
                              {element.description}
                            </div>
                          </div>
                        </div>
                      </div>
                    </SplideSlide>
                  );
                })}
              </SplideTrack>
            </Splide>
          </div>
        </div>
        <div className="about-section">
          <div className="about-title">
            <img src={dot} className="dot-icon" alt="stats" height={14} />
            <div className="about-section-name">В цифрах</div>
          </div>
          <div className="about-info-conteiner">
            {info.map((element, index) => {
              return <InfoBlock info={element} key={index} />;
            })}
          </div>
        </div>
        <div className="about-section" style={{ padding: 0 }}>
          <div
            className="about-title"
            style={{ padding: window.innerWidth > 768 ? "0 108px" : "0 20px" }}
          >
            <img src={dot} className="dot-icon" alt="companies" />
            <div className="about-section-name">
              Компании, которые уже работают с нами
            </div>
          </div>

          <div className="about-logo-container">
            <Marquee>{logoElements}</Marquee>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
