import React, { useState, useRef, useEffect } from "react";
import "./Header.css";
import logo from "../../assets/img/logo.svg";
import Hamburger from "hamburger-react";
import { Box, Modal, Fade } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Header({ isVisible }) {
  const navigate = useNavigate();

  const scrollToSection = (sectionId) => {
    if (window.location.pathname !== "/" && sectionId !== "contacts") {
      navigate("/", { state: { targetId: sectionId } });
    } else {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const [open, setOpen] = useState(false);
  const [crossTop, setCrossTop] = useState(0);
  const [crossLeft, setCrossLeft] = useState(0);
  const crossCoords = useRef();

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setCrossLeft(crossCoords.current.offsetLeft);
      setCrossTop(crossCoords.current.offsetTop);
    }
  }, []);

  return (
    <div className={`header-container ${isVisible ? "visible" : ""}`}>
      <img
        src={logo}
        height={24}
        alt="stellaris innovations"
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigate("/");
        }}
      />
      {window.innerWidth > 786 ? (
        <>
          <ul className="tabs">
            <li className="tab" onClick={() => scrollToSection("projects")}>
              Проекты
            </li>
            <li className="tab" onClick={() => scrollToSection("skills")}>
              Услуги
            </li>
            <li className="tab" onClick={() => scrollToSection("reviews")}>
              Отзывы
            </li>
            <li className="tab" onClick={() => scrollToSection("contacts")}>
              Контакты
            </li>
          </ul>
          <button
            className="header-button"
            onClick={() => scrollToSection("contacts")}
          >
            Написать нам
          </button>
        </>
      ) : (
        <>
          <div ref={crossCoords}>
            <Hamburger
              toggled={open}
              toggle={setOpen}
              size={20}
              distance="lg"
              duration={0.5}
            />
          </div>
          <Modal
            open={open}
            onClose={() => {
              setOpen(false);
            }}
            closeAfterTransition
          >
            <Fade in={open} timeout={750}>
              <Box
                sx={{
                  position: "absolute",
                  width: "90vw",
                  height: "95vh",
                  bgcolor: "white",
                  border: "none",
                  padding: "20px",
                }}
              >
                <div
                  className="cross"
                  style={{
                    position: "absolute",
                    top: crossTop,
                    left: crossLeft,
                  }}
                >
                  <Hamburger
                    toggled={open}
                    toggle={setOpen}
                    size={20}
                    distance="lg"
                    duration={0.5}
                    color="black"
                  />
                </div>
                <ul className="menuTabs">
                  <li
                    className="tab"
                    onClick={() => {
                      scrollToSection("projects");
                      setOpen(false);
                    }}
                  >
                    Проекты
                  </li>
                  <li
                    className="tab"
                    onClick={() => {
                      scrollToSection("skills");
                      setOpen(false);
                    }}
                  >
                    Услуги
                  </li>
                  <li
                    className="tab"
                    onClick={() => {
                      scrollToSection("reviews");
                      setOpen(false);
                    }}
                  >
                    Отзывы
                  </li>
                  <li
                    className="tab"
                    onClick={() => {
                      scrollToSection("contacts");
                      setOpen(false);
                    }}
                  >
                    Контакты
                  </li>
                </ul>
              </Box>
            </Fade>
          </Modal>
        </>
      )}
    </div>
  );
}

export default Header;
