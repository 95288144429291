// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subtitle-container {
  margin-top: 150px;
}

.subtitle-text {
  color: var(--text-secondary);
  font-family: Raleway;
  font-style: normal;
  font-weight: 300;
  text-align: center;
  font-size: 32px;
  line-height: 46px;

  @media (max-width: calc(1224px + 160px)) {
    margin: 0 var(--root-margin);
    font-size: 28px;
    line-height: 46px;
  }

  @media (width < 1088px) {
    line-height: 3.3vw;
    font-size: 2.4vw;
  }
}

.accent-white-text {
  color: var(--text-white);
}

@media screen and (max-width: 1350px) {
  .subtitle-container {
    margin-top: 120px;
  }
}

@media screen and (max-width: 768px) {
  .subtitle-text {
    font-size: 25px;
    line-height: 29px;
    text-align: left;
    margin: 0;
    padding: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Subtitle/Subtitle.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,4BAA4B;EAC5B,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,iBAAiB;;EAEjB;IACE,4BAA4B;IAC5B,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;EAClB;AACF;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,SAAS;IACT,aAAa;EACf;AACF","sourcesContent":[".subtitle-container {\n  margin-top: 150px;\n}\n\n.subtitle-text {\n  color: var(--text-secondary);\n  font-family: Raleway;\n  font-style: normal;\n  font-weight: 300;\n  text-align: center;\n  font-size: 32px;\n  line-height: 46px;\n\n  @media (max-width: calc(1224px + 160px)) {\n    margin: 0 var(--root-margin);\n    font-size: 28px;\n    line-height: 46px;\n  }\n\n  @media (width < 1088px) {\n    line-height: 3.3vw;\n    font-size: 2.4vw;\n  }\n}\n\n.accent-white-text {\n  color: var(--text-white);\n}\n\n@media screen and (max-width: 1350px) {\n  .subtitle-container {\n    margin-top: 120px;\n  }\n}\n\n@media screen and (max-width: 768px) {\n  .subtitle-text {\n    font-size: 25px;\n    line-height: 29px;\n    text-align: left;\n    margin: 0;\n    padding: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
