import React from "react";
import "./Skills.css";
import arrow_right from "../../assets/img/arrow_right.svg";
import TrackVisibility from "react-on-screen";

function SkillCell({ skill }) {
  return (
    <TrackVisibility partialVisibility>
      {({ isVisible }) => (
        <div
          className={`${isVisible ? "animate-left" : "before-animate-left"}`}
        >
          <div
            className="full-screen-skill-container"
            onClick={() => {
              document
                .getElementById("contacts")
                .scrollIntoView({ behavior: "smooth" });
            }}
          >
            <div className="skillcell-container">
              <div className="skillcell-title">{skill.title}</div>
              <div className="skillcell-subtitle-container">
                <div className="skillcell-subtitle">{skill.description}</div>
                <div className="skillcell-tags">
                  {skill.tags.map((element, index) => {
                    return (
                      <div className="skillcell-tag" key={`skill-${index}`}>
                        {element}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="skillcell-price-container">
                <span className="skillcell-price">
                  {skill.price}
                  {window.innerWidth > 768 && <br />}
                  {skill.time}
                </span>
                <img src={arrow_right} className="skillcell-arrow" alt="skill" />
              </div>
            </div>
          </div>
        </div>
      )}
    </TrackVisibility>
  );
}

export default SkillCell;
