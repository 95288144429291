import "./Form.css";
// import avatar from "../../assets/img/avatar.webp";
import telegram from "../../assets/img/telegram.svg";
import emailjs from "@emailjs/browser";
import { useRef, useState } from "react";
import keys from "../../keys.json";
import video from "../../assets/videos/IMG_9581.mp4";

function Form() {
  const form = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [buttonText, setButtonText] = useState("Отправить");
  const [buttonColor, setButtonColor] = useState("#204dc2");
  const [textColor, setTextColor] = useState("#efefef");

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        keys.service_id,
        keys.template_id,
        form.current,
        keys.public_key
      )
      .then(
        (result) => {
          setButtonText("Спасибо за обращение!");
          setButtonColor("#ffff");
          setTextColor("#09090b");
          setName("");
          setEmail("");
          setMessage("");
          setTimeout(() => {
            setButtonText("Отправить");
            setButtonColor("#204dc2");
            setTextColor("#efefef");
          }, 2000);
        },
        (error) => {
          setButtonText("Ошибка");
          setButtonColor("#ffff");
          setTextColor("#09090b");
          setTimeout(() => {
            setButtonText("Отправить");
            setButtonColor("#204dc2");
            setTextColor("#efefef");
          }, 2000);
        }
      );
  };

  return (
    <div className="form-container" id="contacts">
      <div className="form-first-column">
        <div className="form-title">
          Хотите сделать <br />
          <span className="accent-blue-text">проект</span> с нами?
        </div>
        <form className="form-section" ref={form} onSubmit={sendEmail}>
          <div className="inputs-section">
            <div className="input-row">
              <input
                className="input-element"
                type="text"
                id="name"
                name="name"
                placeholder="Имя"
                required
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <input
                className="input-element"
                type="email"
                id="email"
                name="email"
                placeholder="E-mail"
                required
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <textarea
              className="input-element"
              type="text"
              id="text"
              name="message"
              placeholder="Сообщение"
              required
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
          </div>
          <div>
            <button
              className="form-button"
              type="submit"
              value="Send"
              style={{ background: buttonColor, color: textColor }}
            >
              {buttonText}
            </button>
            <div className="form-subscription">
              Нажимая на кнопку, вы соглашаетесь с политикой конфиденциальности
            </div>
          </div>

          <div
            className="telegram-link"
            onClick={() =>
              window.open("https://t.me/stellarisinnovations", "_blank")
            }
          >
            <img src={telegram} alt="stellaris telegram" />
            <div>Написать в телеграм</div>
          </div>
        </form>
      </div>
      {window.innerWidth > 768 && (
        <div className="side-info">
          {/* <img src={avatar} className="form-avatar" alt="stellaris ceo" /> */}
          <div className="form-video-div">
            <video autoPlay loop muted className="form-video">
              <source src={video} type="video/mp4" />
            </video>
          </div>
          <div className="form-name">Даниил Ким</div>
          <div className="form-description">CEO Stellaris Innovations</div>
          {/* <div className="form-comment">
            «Лично буду присутствовать на видеовстрече и вместе найдем решение»
          </div> */}
        </div>
      )}
    </div>
  );
}

export default Form;
