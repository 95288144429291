import React from "react";
import { useSpring, animated } from "react-spring";
import "./About.css";

function InfoBlock({ info }) {
  const imagePath = require(`../../${info.icon}`);

  const targetRef = React.useRef(null);
  const [isVisible, setIsVisible] = React.useState(false);

  const { value } = useSpring({
    from: { value: 0 },
    to: { value: isVisible ? Number(info.value.split(" ")[0]) : 0 },
    delay: 500,
    config: { duration: 800 },
  });

  const handleScroll = () => {
    if (targetRef.current) {
      const rect = targetRef.current.getBoundingClientRect();
      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;

      const elementTopIsVisible = rect.top <= windowHeight;
      const elementBottomIsVisible = rect.bottom >= 0;

      setIsVisible(elementTopIsVisible && elementBottomIsVisible);
    }
  };

  React.useEffect(() => {
    handleScroll();

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="about-block" ref={targetRef}>
      <div className="block-row">
        <div className="block-value">
          <animated.div>{value.to((val) => Math.round(val))}</animated.div>
          <div>{info.value.split(" ")[1]}</div>
        </div>
        <img src={imagePath} className="block-icon" alt="" />
      </div>
      <div className="block-description">{info.description}</div>
    </div>
  );
}

export default InfoBlock;
