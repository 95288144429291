import "./Subtitle.css";
import TrackVisibility from "react-on-screen";

function Subtitle() {
  return (
    <div className="subtitle-container">
      <TrackVisibility>
        {({ isVisible }) => (
          <div className={isVisible ? "animate-left" : "before-animate-left"}>
            <div className="subtitle-text">
              Наша команда состоит из{" "}
              <span className="accent-white-text">опытных специалистов</span>,
              готовых взять на <br /> себя сложные задачи и предоставить вам{" "}
              <span className="accent-white-text">качественные решения</span>.{" "}
              <br /> Мы{" "}
              <span className="accent-white-text">
                гордимся нашими проектами
              </span>{" "}
              и приверженностью к инновациям.
            </div>
          </div>
        )}
      </TrackVisibility>
    </div>
  );
}

export default Subtitle;
