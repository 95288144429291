import { useParams } from "react-router-dom";
import Header from "../../components/Header";
import projects from "./projects.json";
import link_icon from "../../assets/img/link_icon.svg";
import "./PortfolioProject.css";
import Footer from "../../components/Footer";
import Form from "../../components/Form";

function MediaHandler({ path, isCover }) {
  return path.slice(path.length - 4, path.length) === ".mp4" ? (
    <video autoPlay muted loop style={{ width: "100%", borderRadius: "28px" }}>
      <source src={require(`../../${path}`)} type="video/mp4" />
    </video>
  ) : (
    <img
      src={require(`../../${path}`)}
      style={{
        width: isCover ? "100vw" : "100%",
        borderRadius: isCover ? "0" : "28px",
      }}
      alt=""
    />
  );
}

function PortfolioProject() {
  const params = useParams();

  return (
    <>
      <div className="header_wrapper">
        <Header />
      </div>
      <div className="project_container">
        <MediaHandler
          path={projects[params.projectName].cover}
          isCover={true}
        />
        <div className="project_main_info">
          <div className="project_title_container">
            <h2 className="project_title">
              {projects[params.projectName].title}
            </h2>
            {projects[params.projectName].url && (
              <button
                className="project_link_button"
                onClick={() => {
                  window.open(projects[params.projectName].url, "_blank");
                }}
              >
                <img src={link_icon} alt="" className="link-icon" />
              </button>
            )}
          </div>
          <span className="project_description">
            {projects[params.projectName].description}
          </span>
          <div className="project_tags">
            {projects[params.projectName].tags.map((tag, idx) => (
              <span className="project_tag" key={idx}>
                {tag}
              </span>
            ))}
          </div>
        </div>
        <div className="project_images_container">
          {projects[params.projectName].sub_covers.map((sub_cover, idx) => (
            <MediaHandler path={sub_cover} isCover={false} key={idx} />
          ))}
        </div>
        {projects[params.projectName].info.map((obj, index) => {
          return (
            <div key={index} className="project_info_container">
              <div className="project_info_text">
                <h3 className="project_title">{obj.title}</h3>
                <span className="project_description">{obj.description}</span>
              </div>
              <div className="project_images_container">
                {obj.images.map((image, idx) => (
                  <MediaHandler path={image} isCover={false} key={idx} />
                ))}
              </div>
            </div>
          );
        })}
      </div>
      <div className="footer">
        <Form />
        <Footer />
      </div>
    </>
  );
}

export default PortfolioProject;
