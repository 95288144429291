import React, { useState, useEffect } from "react";
import "../../App.css";
import Header from "../../components/Header";
import Title from "../../components/Title";
import Subtitle from "../../components/Subtitle";
import Projects from "../../components/Projects";
import Skills from "../../components/Skills";
import About from "../../components/About";
import Form from "../../components/Form";
import Footer from "../../components/Footer";
import Loader from "../../components/Loader";
import useFontFaceObserver from "use-font-face-observer";
import { useLocation } from "react-router-dom";

function LandingPage() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  const isFontLoaded = useFontFaceObserver([
    {
      family: "Raleway",
      weight: "300",
    },
    {
      family: "Raleway",
      weight: "400",
    },
    {
      family: "Raleway",
      weight: "500",
    },
    {
      family: "Raleway",
      weight: "600",
    },
  ]);

  const { state } = useLocation();
  const { targetId } = state || {};

  useEffect(() => {
    setTimeout(() => {
      const el = document.getElementById(targetId);
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    }, 1500);
  }, [targetId]);

  if (!isFontLoaded) {
    return <Loader />;
  }

  return (
    <>
      <div className="gradient_bg" />
      <div className="main-container">
        <Header isVisible={isVisible} />
        <Title />
        <div className="content">
          <Subtitle />
          <Projects />
          <Skills />
          <About />
          <Form />
          <Footer />
        </div>
      </div>
    </>
  );
}

export default LandingPage;
