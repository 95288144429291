import "../App.css";
import Logo from "../assets/img/logo.svg";

function Loader() {
  return (
    <div className="loader">
      <img className="loaderImg" src={Logo} />
    </div>
  );
}

export default Loader;
