import "./Title.css";
import React from "react";
import analyze_icon from "../../assets/icons/automatization_icon.png";
import forecasting_icon from "../../assets/icons/forecating_icon.png";
import downgrade_icon from "../../assets/icons/downgrade_icon.png";

function Title() {
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    setIsVisible(true);
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className={`cards ${isVisible ? "visible" : ""}`}>
      <div
        className="card"
        style={{
          gap: "40px",
          gridArea: "1 / 1 / 3 / 3",
          padding: "40px",
          justifyContent: window.innerWidth <= 768 ? "space-between" : "auto",
        }}
      >
        <h1>IT компания полного цикла</h1>
        <div className="card_tabs">
          <span className="card_tab">Mobile Apps</span>
          <span className="card_tab">iOS</span>
          <span className="card_tab">Android</span>
          <span className="card_tab">Websites</span>
        </div>
        {window.innerWidth > 768 && (
          <span className="card_description">
            Наша команда специализируется на создании инновационных решений на
            основе искусственного интеллекта, чтобы помочь вам преобразовать и
            оптимизировать свои бизнес-процессы
          </span>
        )}
        <button
          className="card_button"
          onClick={() => {
            scrollToSection("contacts");
          }}
        >
          Начать проект
        </button>
      </div>

      <div className="card" style={{ gap: "16px", gridArea: "1 / 3 / 2 / 4" }}>
        <div className="card_title_cont">
          <h2>Автоматизация процессов</h2>
          <img src={analyze_icon} height={24} alt="" />
        </div>
        {window.innerWidth > 768 && (
          <span>
            Ускорение выполнения повседневных операций и рутинных задач
          </span>
        )}
      </div>

      <div className="card" style={{ gap: "16px", gridArea: "1 / 4 / 2 / 5" }}>
        <div className="card_title_cont">
          <h2>Создание сервисов и приложений</h2>
          <img src={forecasting_icon} height={24} alt="" />
        </div>
        {window.innerWidth > 768 && (
          <span>
            Предлагаем разработку веб сервисов любой сложности, мобильных
            приложений и обычных одностраничников
          </span>
        )}
      </div>

      <div className="card" style={{ gap: "16px", gridArea: "2 / 3 / 3 / 5" }}>
        <div className="card_title_cont">
          <h2>Полный цикл разработки IT продуктов</h2>
          <img src={downgrade_icon} height={24} alt="" />
        </div>
        {window.innerWidth > 768 && (
          <span>
            Глубоко погружаясь в предметную область и анализируя конкурентов,
            создаем уникальный дизайн и разрабатываем качественные IT решения
            для того, чтобы сделать ваш бизнес звездой в мире digital-решений!
          </span>
        )}
      </div>
    </div>
  );
}

export default Title;
