// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./assets/fonts/Raleway-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./assets/fonts/Raleway-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./assets/fonts/Raleway-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./assets/fonts/Raleway-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: var(--black);
  margin: 0;
  box-sizing: border-box;
}

:root {
  --black: #000002;
  --divider-dark: #1f1f1f;
  --white: #fff;

  --bg-blue: #f6f7ff;
  --color-accent: #204dc2;
  --hover-services: #09090b;

  --text-white: #efefef;
  --text-secondary: #505050;
  --text-secondary-2: #7e7e7e;

  --max-root-width: 1224px;
  --root-margin: 70px;
}

@font-face {
  font-weight: 300;
  font-family: "Raleway";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

@font-face {
  font-weight: 400;
  font-family: "Raleway";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

@font-face {
  font-weight: 500;
  font-family: "Raleway";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
}

@font-face {
  font-weight: 600;
  font-family: "Raleway";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,SAAS;EACT,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;EACvB,aAAa;;EAEb,kBAAkB;EAClB,uBAAuB;EACvB,yBAAyB;;EAEzB,qBAAqB;EACrB,yBAAyB;EACzB,2BAA2B;;EAE3B,wBAAwB;EACxB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,sBAAsB;EACtB,4CAA4C;AAC9C;;AAEA;EACE,gBAAgB;EAChB,sBAAsB;EACtB,4CAA8C;AAChD;;AAEA;EACE,gBAAgB;EAChB,sBAAsB;EACtB,4CAA6C;AAC/C;;AAEA;EACE,gBAAgB;EAChB,sBAAsB;EACtB,4CAA+C;AACjD","sourcesContent":["body {\n  background-color: var(--black);\n  margin: 0;\n  box-sizing: border-box;\n}\n\n:root {\n  --black: #000002;\n  --divider-dark: #1f1f1f;\n  --white: #fff;\n\n  --bg-blue: #f6f7ff;\n  --color-accent: #204dc2;\n  --hover-services: #09090b;\n\n  --text-white: #efefef;\n  --text-secondary: #505050;\n  --text-secondary-2: #7e7e7e;\n\n  --max-root-width: 1224px;\n  --root-margin: 70px;\n}\n\n@font-face {\n  font-weight: 300;\n  font-family: \"Raleway\";\n  src: url(\"./assets/fonts/Raleway-Light.ttf\");\n}\n\n@font-face {\n  font-weight: 400;\n  font-family: \"Raleway\";\n  src: url(\"./assets/fonts/Raleway-Regular.ttf\");\n}\n\n@font-face {\n  font-weight: 500;\n  font-family: \"Raleway\";\n  src: url(\"./assets/fonts/Raleway-Medium.ttf\");\n}\n\n@font-face {\n  font-weight: 600;\n  font-family: \"Raleway\";\n  src: url(\"./assets/fonts/Raleway-SemiBold.ttf\");\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
