import React from "react";
import "./Projects.css";
import Card from "./Card";
import TrackVisibility from "react-on-screen";

import projects from "./projects_list.json";

function Projects() {
  return (
    <div className="projects-container" id="projects">
      <TrackVisibility>
        {({ isVisible }) => (
          <div className={isVisible ? "animate-right" : "before-animate-right"}>
            <h2 className="section-name">Проекты</h2>
          </div>
        )}
      </TrackVisibility>
      <div className="card-container">
        {projects.map((element, index) => {
          return (
            <Card project={element} index={index + 1} key={`card-${index}`} />
          );
        })}
      </div>
    </div>
  );
}

export default Projects;
