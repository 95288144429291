import React from "react";
import "./Projects.css";
import link_icon from "../../assets/img/link_icon.svg";
import TrackVisibility from "react-on-screen";
import { useNavigate } from "react-router-dom";

function Card({ project, index }) {
  const imagePath = require(`../../${project.image}`);

  const navigate = useNavigate();

  return (
    <div
      className="item"
      onClick={() => {
        if (project.path) {
          navigate(project.path);
          window.scrollTo(0, 0);
        }
      }}
    >
      <TrackVisibility partialVisibility>
        {({ isVisible }) => (
          <div
            className={`${isVisible ? "animate-bottom" : "before-animate-bottom"
              }`}
          >
            <div className="card-img-container">
              <img src={imagePath} className="card-img" alt="project" />
            </div>
            <div>
              <div className="card-title-row">
                <div className="card-title">{project.title}</div>
                {project.path && (
                  <button className="card-link-button">
                    <img src={link_icon} alt="" className="link-icon" />
                  </button>
                )}
              </div>
              <div className="card-subtitle">{project.subtitle}</div>
              <div className="card-bottom-row">
                {project.tags.map((element, index) => {
                  return (
                    <div className="card-tag" key={`card-${index}`}>
                      {element}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </TrackVisibility>
    </div>
  );
}

export default Card;
