import "./Footer.css";
import logo from "../../assets/img/logo.svg";

function Footer() {
  return (
    <div className="footer-container">
      {window.innerWidth > 768 && (
        <div className="footer-caption">Все права защищены</div>
      )}
      <img
        src={logo}
        className="footer-logo"
        alt="stellaris innovations"
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      />

      <a href="mailto:stellarisinnovations@yandex.ru" className="footer-email">
        stellarisinnovations@yandex.ru
      </a>
    </div>
  );
}

export default Footer;
