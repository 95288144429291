// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: var(--max-root-width);
  padding-bottom: 40px;
  align-items: flex-end;

  @media (max-width: calc(1224px + 160px)) {
    padding: 0 9.7vw 20px 9.7vw;
  }
}

.footer-caption {
  color: var(--text-secondary, #505050);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  width: 33.3%;
}

.footer-logo {
  cursor: pointer;
  height: 24px;
  -webkit-user-select: none;
          user-select: none;
  width: 33.3%;
}

.footer-email {
  cursor: pointer;
  color: var(--text-secondary, #505050);
  font-variant-numeric: lining-nums proportional-nums;
  font-family: Raleway;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  text-decoration: none;
  text-align: right;
  width: 33.3%;

  @media screen and (max-width: 768px) {
    font-size: 12px;
    width: auto;
  }
}

@media screen and (max-width: 768px) {
  .footer-caption {
    font-size: 10px;
  }
  .footer-logo {
    height: 14px;
    width: auto;
  }
  .footer-email {
    font-size: 12px;
    width: auto;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,gCAAgC;EAChC,oBAAoB;EACpB,qBAAqB;;EAErB;IACE,2BAA2B;EAC7B;AACF;;AAEA;EACE,qCAAqC;EACrC,mDAAmD;EACnD,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,yBAAiB;UAAjB,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,qCAAqC;EACrC,mDAAmD;EACnD,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,qBAAqB;EACrB,iBAAiB;EACjB,YAAY;;EAEZ;IACE,eAAe;IACf,WAAW;EACb;AACF;;AAEA;EACE;IACE,eAAe;EACjB;EACA;IACE,YAAY;IACZ,WAAW;EACb;EACA;IACE,eAAe;IACf,WAAW;EACb;AACF","sourcesContent":[".footer-container {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  max-width: var(--max-root-width);\n  padding-bottom: 40px;\n  align-items: flex-end;\n\n  @media (max-width: calc(1224px + 160px)) {\n    padding: 0 9.7vw 20px 9.7vw;\n  }\n}\n\n.footer-caption {\n  color: var(--text-secondary, #505050);\n  font-variant-numeric: lining-nums proportional-nums;\n  font-family: Raleway;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 16px;\n  width: 33.3%;\n}\n\n.footer-logo {\n  cursor: pointer;\n  height: 24px;\n  user-select: none;\n  width: 33.3%;\n}\n\n.footer-email {\n  cursor: pointer;\n  color: var(--text-secondary, #505050);\n  font-variant-numeric: lining-nums proportional-nums;\n  font-family: Raleway;\n  font-size: 18px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: 100%;\n  text-decoration: none;\n  text-align: right;\n  width: 33.3%;\n\n  @media screen and (max-width: 768px) {\n    font-size: 12px;\n    width: auto;\n  }\n}\n\n@media screen and (max-width: 768px) {\n  .footer-caption {\n    font-size: 10px;\n  }\n  .footer-logo {\n    height: 14px;\n    width: auto;\n  }\n  .footer-email {\n    font-size: 12px;\n    width: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
