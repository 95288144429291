import React from "react";
import "./Skills.css";
import SkillCell from "./SkillCell";
import skills from "./skils_list.json";
import TrackVisibility from "react-on-screen";

function Skills() {
  return (
    <div className="skills-container" id="skills">
      <TrackVisibility>
        {({ isVisible }) => (
          <div className={isVisible ? "animate-left" : "before-animate-left"}>
            <h2 className="section-name">Что мы умеем?</h2>
          </div>
        )}
      </TrackVisibility>
      <div className="skills-cells-container">
        {skills.map((element, index) => {
          return <SkillCell skill={element} key={`skill-cell-${index}`} />;
        })}
      </div>
    </div>
  );
}

export default Skills;
